@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.standard-table span {
  color: #494949;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.btn-contrast:hover {
  svg {
    fill: #fff;
  }
}

.btn-dark-contrast:hover {
  svg {
    fill: rgb(55, 65, 81);
  }
}

.monospace {
  font-family: monospace;
}

.data-table {
  border-collapse: separate;
  border-spacing: 0 0.3em;
  width: 100%;
  table-layout: fixed;
  font-size: small;
}

.data-table th {
  padding-bottom: 1em;
  @apply border-b;
}

.data-table td {
  text-align: center;
}

.availability-table, .full-width-table {
  border-collapse: collapse;
  border-spacing: 0 1em;
  width: 100%;
  table-layout: fixed;

  td {
    height: 3rem;
    text-align: center;
    vertical-align: middle;
    margin: 0 auto;
  }

  tr {
    padding: 5em 0 5em 0;
  }
}

.availability-per-day-table {
  th {
    border-bottom: 1px solid #e5e5e5;
  }
}

.availability-table tr {
  padding: 5em 0 5em 0;
}

.availability-rows td {
  height: 3rem;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  //width: 150px;
}


@responsive {
  .shadow-top-md {
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }

  .shadow-bottom-md {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  }

  .shadow-bottom-lg {
    -webkit-box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .shadow-right-md {
    -webkit-box-shadow: 11px 0px 15px -1px rgba(0, 0, 0, 0.10);
    box-shadow: 11px 0px 15px -1px rgba(0, 0, 0, 0.10);
  }

  .inner-shadow-md {
    -moz-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.10);
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.10);
  }

  .inner-shadow-lg {
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.20);
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.10);
  }

  .text-shadow-heavy {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.60);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

.tr {
  display: table-row;
}

.td {
  display: table-cell;
}


.planner-rounds-table {
  border-collapse: separate;
  table-layout: fixed;

  .planner-table-row {
    display: table-row;
    border-bottom: 1px solid #e5e5e5;
  }

  .planner-table-row:last-of-type {
    .plannable-item {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .planner-table-row {
    border-bottom: 1px solid #e5e5e5;
  }

  .plannable-item {
    border-right: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }

  .planner-table-topic-row-cell {
    display: table-cell;
    z-index: 5;
    position: sticky;
    width: 100px;
    left: 0;
  }

  .planner-table-row-cell {
    z-index: 5;
    position: sticky;
    left: 0;
    vertical-align: top;
  }

  .planner-table-cell {
    border-right: 1px solid #e5e5e5;
    overflow: hidden;
  }

  .planner-header-row {
    @apply shadow-bottom-md;

    position: relative;
    display: table-row;

    .planner-header-topic {
      position: sticky;
      left: 0;
      top: 0;
      border-left: 1px solid #e5e5e5;
    }

    .planner-header-column {
      position: sticky;
      top: 0;
    }

    .planner-th {
      display: table-cell;
      background: white;
      border-right: 1px solid #e5e5e5;
      width: 10rem;
    }
  }
}